



































import { Vue, Component } from 'vue-property-decorator'
import UserAPI from '@/api/requests/user'
import { lkETUName } from '@/config/urls'

@Component
export default class Auth extends Vue {
  private lkETUName_ = lkETUName

  private redirectToETU() {
    UserAPI.loginWithLk()
  }

  private redirectToPartnerLk() {
    UserAPI.loginWithPartnerLk(this.$route.query.redirect as string)
  }

  private redirectToUserAuth() {
    this.$router.push('/auth/user')
  }
}
